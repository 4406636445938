<template>
  <div>
    <b-row>
      <b-col xl="3" lg="4" md="5">
        <FormCardProfileVue :loading="loading" />
      </b-col>
      <b-col xl="9" lg="8" md="7">
        <b-tabs>
          <b-tab>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Empresa</span>
            </template>
            <FormVue :loading="loading" />
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="DollarSignIcon" />
              <span>Financeiro</span>
            </template>
            <FormFinacialVue />
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Documentos</span>
            </template>
            <FormDocumentationsVue />
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="PrinterIcon" />
              <span>Relatorios</span>
            </template>
            <FormReportsVue />
          </b-tab>
          -->
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'

import mixinsGlobal from '@/mixins'
import FormVue from './forms/personal/FormFornecedor.vue'
import FormFinacialVue from './forms/finance/FormFinancial.vue'
import FormDocumentationsVue from './forms/documentations/FormDocumentations.vue'
import FormReportsVue from './forms/report/GridReport.vue'
import FormCardProfileVue from './forms/personal/FormCardProfileFornecedor.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    FormCardProfileVue,
    FormVue,
    FormFinacialVue,
    FormDocumentationsVue,
    FormReportsVue
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      loading: true
    }
  },
  async mounted() {
    this.loading = true
    try {
      const response = await this.$store.dispatch(
        'fornecedorAdministrativeModule/getFornecedorID',
        this.$route.params.id
      )
      const object = response.data
      if (object.foto) {
        object.foto = `data:image/jpeg;base64,${object.foto}`
      }
      await this.$store.dispatch(
        'fornecedorAdministrativeModule/prepareToEditFull',
        object
      )
      await this.$store.dispatch('combosModule/loadComboNaturezaFiscal')
      await this.$store.dispatch('combosModule/loadComboUfs')
      await this.$store.dispatch('combosModule/loadComboPrevidenciaSocial')
      await this.$store.dispatch('combosModule/loadComboTipoEndereco')
      await this.$store.dispatch(
        'fornecedorAdministrativeModule/loadComboTypesRegister'
      )
      this.loading = false
    } catch {
      this.MensagemError('Error', 'Erro ao carregar dados da tela!')
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
